/**
 * The identifier for retrieving a grid editor configuration aspect.
 */
import { IMetaAspect } from '@quino/core';

export const GridEditorConfigurationAspectIdentifier = 'ControlParameters';

/**
 * Grid editor configuration aspect with the records per page
 */
export interface IGridEditorConfigurationAspect extends IMetaAspect {
    recordsperpage: number;
    parentpropstoreloadafterchange?: string;
}
