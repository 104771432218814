import React, { useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import {
    ISessionService,
    Markdown,
    PopupButtonBar,
    SHARED_SERVICE_IDENTIFIER,
    useI18n,
} from 'performa-shared';
import { useService } from '@quino/ui';
import { ILanguageService, QuinoCoreServiceSymbols } from '@quino/core';

export const AuthErrorPopup = (props: { error: string; userEmail: string | null }) => {
    const sessionService = useService<ISessionService>(SHARED_SERVICE_IDENTIFIER.ISESSIONSERVICE);
    const languageService = useService<ILanguageService>(QuinoCoreServiceSymbols.ILanguageService);
    const i18n = useI18n();

    const [languageLoaded, setLanguageLoaded] = useState<boolean>(false);
    const [translationLoaded, setTranslationsLoaded] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            const locale = await languageService.getCurrentLanguageAsync();
            sessionService.updateSessionState({ locale: locale });
            setLanguageLoaded(true);
        })();
    }, []);

    useEffect(
        () => {
            if (languageLoaded) {
                (async () => {
                    await i18n.loadTranslationsAsync();
                    setTranslationsLoaded(true);
                })();
            }
        },
        [languageLoaded]
    );

    return (
        <Popup
            showTitle={true}
            title={i18n.t('literal.CustomLiterals.AuthError.Title')}
            closeOnOutsideClick={false}
            showCloseButton={false}
            visible={translationLoaded}
            width={400}
            height={'auto'}
        >
            <Markdown>
                {props.userEmail
                    ? i18n.t('literal.CustomLiterals.AuthErrorWithUserEmail.Text', {
                          error: props.error,
                          userEmail: props.userEmail,
                      })
                    : i18n.t('literal.CustomLiterals.AuthError.Text', {
                          error: props.error,
                      })}
            </Markdown>

            <PopupButtonBar>
                <Button
                    onClick={() => document.location.reload()}
                    type={'default'}
                    text={'Retry'}
                />
            </PopupButtonBar>
        </Popup>
    );
};
