import { IMetaAspect } from '@quino/core';

/**
 * The identifier for the aspect.
 */
export const PerformaLabelAspectIdentifier = 'ControlParameters';

/**
 * custom query aspect which contains the text color
 */
export interface IPerformaLabelAspect extends IMetaAspect {
    csstextcolor: string;
}
