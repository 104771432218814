import { TSharedTranslationKey } from 'performa-shared';

export const defaultSBVTranslations = {
    'literal.CustomLiterals.SBV.MainMenu.CurrentQuarter': 'Aktuelle Umfragen',
    'literal.CustomLiterals.SBV.MainMenu.PastQuarters': 'Vergangene Umfragen',
    'literal.CustomLiterals.SBV.MainMenu.SurveyResultA': 'Personalbestand (A)',
    'literal.CustomLiterals.SBV.MainMenu.SurveyResultL': 'Lohnsummendeklaration',
    'literal.CustomLiterals.SBV.MainMenu.SurveyResultB': 'Auftragssituation (B)',
    'literal.CustomLiterals.SBV.MainMenu.PayrollReport': 'Deklaration (M)',
};

export type TSBVTranslationKey = TSharedTranslationKey | keyof typeof defaultSBVTranslations;

/**
 * proxy function to type check translation key
 * @param key
 */
export const tKey = (key: TSBVTranslationKey): TSBVTranslationKey => key;
