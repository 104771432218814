import React from 'react';
import { DataBrowser, ISiteMap } from 'performa-shared';
import { tKey } from '../lang/TranslationKeys';

export const getSbvSiteMap = (): ISiteMap => ({
    nodes: [
        {
            id: 'current_quarter',
            translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.CurrentQuarter'),
            items: [
                {
                    path: 'sbv_surveyresulta',
                    translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.SurveyResultA'),
                    icon: 'people',
                    main: () => <DataBrowser entityName="sbv_surveyresulta" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('sbv_surveyresulta').canRead(),
                },
                {
                    path: 'sbv_surveyresultb',
                    translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.SurveyResultB'),
                    icon: 'assessment',
                    main: () => <DataBrowser entityName="sbv_surveyresultb" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('sbv_surveyresultb').canRead(),
                },
                {
                    path: 'sbv_surveyresultl',
                    translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.SurveyResultL'),
                    icon: 'monetization_on',
                    main: () => <DataBrowser entityName="sbv_surveyresultl" />,
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('sbv_surveyresultl').canRead(),
                },
                {
                    path: 'ambcust_payrollreport',
                    translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.PayrollReport'),
                    icon: 'monetization_on',
                    main: () => (
                        <DataBrowser
                            entityName="ambcust_payrollreport"
                            defaultListLayoutName={'Portal_Deklarationen'}
                        />
                    ),
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('ambcust_payrollreport').canRead(),
                },
            ],
        },
        {
            id: 'past_quarters',
            translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.PastQuarters'),
            items: [
                {
                    path: 'sbv_surveyresulta_inactive',
                    translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.SurveyResultA'),
                    icon: 'people',
                    main: () => (
                        <DataBrowser
                            entityName="sbv_surveyresulta"
                            listLayoutFilter={(layout) => layout.name.includes('Inaktive_')}
                        />
                    ),
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('sbv_surveyresulta').canRead(),
                },
                {
                    path: 'sbv_surveyresultb_inactive',
                    translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.SurveyResultB'),
                    icon: 'assessment',
                    main: () => (
                        <DataBrowser
                            entityName="sbv_surveyresultb"
                            listLayoutFilter={(layout) => layout.name.includes('Inaktive_')}
                        />
                    ),
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('sbv_surveyresultb').canRead(),
                },
                {
                    path: 'sbv_surveyresultl_inactive',
                    translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.SurveyResultL'),
                    icon: 'monetization_on',
                    main: () => (
                        <DataBrowser
                            entityName="sbv_surveyresultl"
                            listLayoutFilter={(layout) => layout.name.includes('Inaktive_')}
                        />
                    ),
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('sbv_surveyresultl').canRead(),
                },
                {
                    path: 'ambcust_payrollreport_inactive',
                    translationKey: tKey('literal.CustomLiterals.SBV.MainMenu.PayrollReport'),
                    icon: 'monetization_on',
                    main: () => (
                        <DataBrowser
                            entityName="ambcust_payrollreport"
                            listLayoutFilter={(layout) => layout.name.includes('Inaktive_')}
                        />
                    ),
                    isVisible: (item, authContext) =>
                        authContext.getAuthorization('ambcust_payrollreport').canRead(),
                },
            ],
        },
    ],
});
