import React from 'react';
import { PageRoot } from 'performa-shared';
import { SbvLogo, SbvLoggedOutDecorator } from './Components';
import { getSbvSiteMap } from './core/SbvSiteMap';

export class App extends React.Component {
    render() {
        return (
            <PageRoot
                headerIcon={SbvLogo}
                siteMap={getSbvSiteMap()}
                loggedOutPageDecorator={SbvLoggedOutDecorator}
            />
        );
    }
}
