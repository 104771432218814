import { TQuinoUiTranslationKey } from '@quino/ui';

// prettier-ignore
export const defaultSharedTranslations = {
    'literal.CustomLiterals.Application.Title': 'Performa Application',
    'literal.CustomLiterals.Application.ToStartPage': 'Zur Startseite',
    'literal.CustomLiterals.BlockedBrowserMessage.Markdown': '# Browser nicht unterstützt\nIhr Browser wird nicht unterstützt. Bitte verwenden Sie einen anderen Browser.',
    'literal.CustomLiterals.ChangePassword.Cancel': 'Abbrechen',
    'literal.CustomLiterals.ChangePassword.Confirm': 'Ok',
    'literal.CustomLiterals.ChangePassword.ConfirmNewPassword': 'Neues Passwort Bestätigen',
    'literal.CustomLiterals.ChangePassword.NewPassword': 'Neues Passwort',
    'literal.CustomLiterals.ChangePassword.OldPassword': 'Aktuelles Passwort',
    'literal.CustomLiterals.ChangePassword.Submit': 'Neues Passwort setzen',
    'literal.CustomLiterals.ChangePassword.SuccessMessage': 'Das Passwort wurde geändert.',
    'literal.CustomLiterals.ChangePassword.Title': 'Passwort ändern',
    'literal.CustomLiterals.ChangePassword.Validation.Mismatch': 'Passwörter stimmen nicht überein',
    'literal.CustomLiterals.ChangePassword.Validation.MissingRequiredFields': 'Darf nicht leer sein',
    'literal.CustomLiterals.ChangePassword.Validation.ServerError': 'Konnte das Passwort nicht ändern. Stellen Sie sicher, dass das aktuelle Passwort korrekt ist',
    'literal.CustomLiterals.Confirm': 'Ok',
    'literal.CustomLiterals.ConfirmEmail.Error': 'Die Verifikation der E-Mail-Adresse ist fehlgeschlagen.',
    'literal.CustomLiterals.ConfirmEmail.Success': 'Die E-Mail-Adresse wurde erfolgreich verifiziert.',
    'literal.CustomLiterals.ConfirmEmail.Title': 'E-Mail-Adresse verifizieren',
    'literal.CustomLiterals.CrmContextWrapper.ErrorCouldNotLoadFetchContexts': 'Error: Could not fetch contexts. Message: {{errorMessage}}',
    'literal.CustomLiterals.Dashboard.Message': '## Herzlich Willkommen!', // Markdown supported
    'literal.CustomLiterals.DataBrowser.ErrorCouldNotLoadData': 'Keine Daten oder keine Konfiguration',
    'literal.CustomLiterals.DataBrowser.ErrorListLayoutNotFound': 'Listen-Layout (View) mit Name [{{layoutName}}] nicht gefunden!',
    'literal.CustomLiterals.DataBrowser.ErrorNoListLayoutsAvailableYet': 'Keine Listen-Layouts (Views) verfügbar!',
    'literal.CustomLiterals.DataBrowser.ErrorUnableToDetermineLayoutName': 'Name des Listen-Layouts (View) konnte nicht ermittelt werden!',
    'literal.CustomLiterals.DataBrowser.ErrorUnknownView': 'Fehler: Unbekannte Ansicht!',
    'literal.CustomLiterals.DataBrowser.Detail.ExportFailed': 'Die Daten konnten nicht exportierten werden',
    'literal.CustomLiterals.DataBrowser.Detail.ExportFailed.Title': 'Fehler',
    'literal.CustomLiterals.DataBrowser.Detail.NotFound.Title': 'Eintrag konnte nicht geladen werden',
    'literal.CustomLiterals.DataBrowser.Detail.NotFound.Text': 'Der Eintrag existiert nicht oder wurde gelöscht und steht nicht mehr zur Verfügung. Sie werden nun zur Übersicht der Einträge weitergeleitet.',
    'literal.CustomLiterals.DataBrowser.Detail.LayoutNotFound.Title': 'Kein Layout für den Eintrag gefunden',
    'literal.CustomLiterals.DataBrowser.Detail.LayoutNotFound.Text': 'Es existiert kein Layout für den Eintrag "{{entity}}".',
    'literal.CustomLiterals.DataBrowser.List.LayoutNotFound.Title': ' Layout für Liste nicht gefunden',
    'literal.CustomLiterals.DataBrowser.List.LayoutNotFound.Text': 'Layout "{{layout}}" für den Eintrag "{{entity}}" wurde nicht gefunden.',
    'literal.CustomLiterals.DataBrowser.LoadingAuthorizations': 'Berechtigungen werden geladen ...',
    'literal.CustomLiterals.DataBrowser.LoadingData': 'Daten werden geladen ...',
    'literal.CustomLiterals.DataList.ErrorCouldNotFindListLayout': 'Konnte keine Listeninformationen finden!',
    'literal.CustomLiterals.DataList.Filter': 'Filter',
    'literal.CustomLiterals.DataList.New': 'Neuer Eintrag',
    'literal.CustomLiterals.ForgotPassword.BackButton': 'Zurück zur Anmeldung',
    'literal.CustomLiterals.ForgotPassword.EmailSentDialog.BackButton': 'Zurück zur Anmeldung',
    'literal.CustomLiterals.ForgotPassword.EmailSentDialog.Text': 'Falls Sie über ein aktives Benutzerkonto verfügen, wurde Ihnen soeben per E-Mail ein Link zum Zurücksetzen des Passworts zugestellt.',
    'literal.CustomLiterals.ForgotPassword.EmailSentDialog.Title': 'E-Mail gesendet',
    'literal.CustomLiterals.ForgotPassword.ErrorMessage': 'Ein Fehler ist aufgetreten: {{errorMessage}}',
    'literal.CustomLiterals.ForgotPassword.ResetPasswordButton': 'Passwort zurücksetzen',
    'literal.CustomLiterals.ForgotPassword.Title': 'Passwort vergessen',
    'literal.CustomLiterals.ForgotPassword.UserNamePlaceholderText': 'Benutzername',
    'literal.CustomLiterals.Header.OpenSidebar': 'Aufklappen',
    'literal.CustomLiterals.LocalizedLabel.AspectError': 'Error while loading of LocalizedLabel. No "TranslationKeyAspect" found.',
    'literal.CustomLiterals.LocalizedLabel.TranslationKeyError': 'Error while loading LocalizedLabel. No matching translation key found.',
    'literal.CustomLiterals.Login.CustomText': '', // Markdown supported
    'literal.CustomLiterals.Login.EmailAddressPlaceholder': 'Email-Adresse',
    'literal.CustomLiterals.Login.ForgotPasswordButton': 'Passwort vergessen?',
    'literal.CustomLiterals.Login.GoToLogin': 'Zum Login',
    'literal.CustomLiterals.Login.InvalidLogin.Simple': 'Ihr Benutzername oder Kennwort ist nicht korrekt.',
    'literal.CustomLiterals.Login.InvalidLogin': 'Ihr Benutzername oder Kennwort ist nicht korrekt. Wenn Sie Ihr Kennwort nicht mehr wissen, klicken Sie auf «Passwort vergessen».',
    'literal.CustomLiterals.Login.LoginButton': 'Anmelden',
    'literal.CustomLiterals.Login.PasswordPlaceholder': 'Passwort',
    'literal.CustomLiterals.Login.Register': 'Registrieren',
    'literal.CustomLiterals.Login.SessionExpired.Title': 'Sitzung abgelaufen, bitte anmelden',
    'literal.CustomLiterals.Login.ShowPassword': 'Passwort anzeigen/verbergen',
    'literal.CustomLiterals.Login.Title': 'Anmelden',
    'literal.CustomLiterals.MainLayout.CrmContextCancelButton': 'Abbrechen',
    'literal.CustomLiterals.MainLayout.CrmContextChangeTitle': 'Kontext wechseln',
    'literal.CustomLiterals.MainLayout.CrmContextSearch': 'Suchen...',
    'literal.CustomLiterals.MainLayout.CrmContextSearchNoResult': 'Es wurde kein Kontext für Ihre Suche gefunden.',
    'literal.CustomLiterals.MainLayout.CrmContextNoContextText': 'Es ist noch kein Kontext für Sie definiert. Bitte wenden sie sich an den Administrator des Systems.',
    'literal.CustomLiterals.MainLayout.CrmContextSelectionTitle': 'Kontext auswählen',
    'literal.CustomLiterals.PasswordPolicy.Errors.AboveMaximumLength': 'Das Passwort darf maximal {{amount}} Zeichen enthalten.',
    'literal.CustomLiterals.PasswordPolicy.Errors.BelowMinimumLength': 'Das Passwort muss mindestens {{amount}} Zeichen enthalten.',
    'literal.CustomLiterals.PasswordPolicy.Errors.MissingLowercaseCharacter': 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten.',
    'literal.CustomLiterals.PasswordPolicy.Errors.MissingNumberCharacter': 'Das Passwort muss mindestens eine Zahl enthalten.',
    'literal.CustomLiterals.PasswordPolicy.Errors.MissingSpecialCharacter': 'Das Passwort muss mindestens eines der folgenden Sonderzeichen enthalten: {{allowedSpecialChars}}.',
    'literal.CustomLiterals.PasswordPolicy.Errors.MissingUppercaseCharacter': 'Das Passwort muss mindestens einen Grossbuchstaben enthalten.',
    'literal.CustomLiterals.PasswordReset.BackButton': 'Zurück zur Anmeldung',
    'literal.CustomLiterals.PasswordReset.Dialog.BackButton': 'Zum Login',
    'literal.CustomLiterals.PasswordReset.Dialog.Text': 'Das Passwort wurde erfolgreich aktualisiert.',
    'literal.CustomLiterals.PasswordReset.Dialog.Title': 'Passwort aktualisiert',
    'literal.CustomLiterals.PasswordReset.ErrorPasswordsDontMatch': 'Die eingegebenen Passwörter stimmen nicht überein. Bitte neu erfassen.',
    'literal.CustomLiterals.PasswordReset.ErrorResetTokenExpired': 'Ihre Angaben sind nicht korrekt oder die Gültigkeit für die Zurücksetzung des Passworts ist abgelaufen. Bitte kontrollieren Sie den Benutzernamen oder klicken Sie nochmals auf die Funktion «Passwort vergessen».',
    'literal.CustomLiterals.PasswordReset.ErrorUsernameMustBeNotEmpty': 'Benutzername darf nicht leer sein',
    'literal.CustomLiterals.PasswordReset.Password2PlaceholderText': 'Passwort wiederholen',
    'literal.CustomLiterals.PasswordReset.PasswordPlaceholderText': 'Passwort',
    'literal.CustomLiterals.PasswordReset.SetNewPasswordButton': 'Neues Passwort setzen',
    'literal.CustomLiterals.PasswordReset.Title': 'Passwort zurücksetzen',
    'literal.CustomLiterals.PasswordReset.UserNamePlaceholderText': 'Benutzername',
    'literal.CustomLiterals.Profile.Discard': 'Verwerfen',
    'literal.CustomLiterals.Profile.Field.Branch': 'Filiale',
    'literal.CustomLiterals.Profile.Field.Address': 'Adresse',
    'literal.CustomLiterals.Profile.Field.Company': 'Unternehmen',
    'literal.CustomLiterals.Profile.Field.EmailAddress': 'Email-Addresse',
    'literal.CustomLiterals.Profile.Field.FirstName': 'Vorname',
    'literal.CustomLiterals.Profile.Field.Language': 'Sprache',
    'literal.CustomLiterals.Profile.Field.LastName': 'Nachname',
    'literal.CustomLiterals.Profile.Field.Password': 'Passwort',
    'literal.CustomLiterals.Profile.Field.PasswordConfirmation': 'Passwort bestätigen',
    'literal.CustomLiterals.Profile.Field.ShowFieldDescriptions': 'Hilfetexte anzeigen',
    'literal.CustomLiterals.Profile.LoadError': 'Profilinformationen konnten nicht geladen werden.',
    'literal.CustomLiterals.Profile.Save': 'Speichern',
    'literal.CustomLiterals.Profile.SaveError': 'Speichern des Profils fehlgeschlagen',
    'literal.CustomLiterals.Profile.ShowDesciptions.Always': 'Immer',
    'literal.CustomLiterals.Profile.ShowDesciptions.Hover': 'Als Tooltip',
    'literal.CustomLiterals.Profile.ShowDesciptions.Never': 'Nie',
    'literal.CustomLiterals.Profile.Title': 'Profil',
    'literal.CustomLiterals.Profile.UserSettings.Error.Title': 'Fehler',
    'literal.CustomLiterals.Profile.UserSettings.Error.Message': 'Es ist ein Fehler beim Laden der Benutzereinstellungen aufgetreten. Bitte loggen Sie sich erneut ein.', // Markdown supported
    'literal.CustomLiterals.ProfileForm.LeadinText': 'Bitte füllen Sie das folgende Formular aus.', // Markdown supported
    'literal.CustomLiterals.ProfileForm.LeadoutText': 'Bitte überprüfen Sie Ihre Angaben.', // Markdown supported
    'literal.CustomLiterals.ProfileForm.Validation.InvalidEmailAddressFormat': 'Ungültiges Format der Email-Adresse',
    'literal.CustomLiterals.ProfileForm.Validation.MissingEmailAddress': 'Email-Adresse ist zwingend',
    'literal.CustomLiterals.ProfileForm.Validation.MissingFirstName': 'Vorname ist zwingend',
    'literal.CustomLiterals.ProfileForm.Validation.MissingLanguage': 'Sprache ist zwingend',
    'literal.CustomLiterals.ProfileForm.Validation.MissingLastName': 'Nachname ist zwingend',
    'literal.CustomLiterals.ProfileForm.Validation.MissingBranch': 'Filiale ist zwingend',
    'literal.CustomLiterals.ProfileForm.Validation.MissingCompany': 'Unternehmen ist zwingend',
    'literal.CustomLiterals.ProfileForm.Validation.MissingAddress': 'Adresse ist zwingend',
    'literal.CustomLiterals.ServerNotReachable': '# Server nicht erreichbar', // Markdown supported
    'literal.CustomLiterals.ServerRestart.Failure': 'Neustart fehlgeschlagen. Der Server meldete: {{errorMessage}}',
    'literal.CustomLiterals.ServerRestart.Submit': 'Server neu starten',
    'literal.CustomLiterals.ServerRestart.Success': 'Neustart erfolgreich angestossen',
    'literal.CustomLiterals.Upload.UploadButton': 'Dateien auswählen',
    'literal.CustomLiterals.Upload.DropField': '... oder hier ablegen',
    'literal.CustomLiterals.Upload.DownLoadError': 'Die Datei konnte nicht heruntergeladen werden',
    'literal.CustomLiterals.Upload.UploadError': 'Dateien konnten nicht gespeichert werden',
    'literal.CustomLiterals.UserMenu.ChangePassword': 'Passwort ändern',
    'literal.CustomLiterals.UserMenu.Profile': 'Profil',
    'literal.CustomLiterals.UserMenu.Settings': 'Einstellungen',
    'literal.CustomLiterals.UserMenu.SignOut': 'Abmelden',
    'literal.CustomLiterals.UserRegistration.Dialog.Confirm': 'Zur Anmeldung',
    'literal.CustomLiterals.UserRegistration.Dialog.Text': 'Die Registration war erfolgreich. In Kürze erhalten Sie ein E-Mail, um ihre E-Mail-Adresse zu bestätigen. Bitte bestätigen Sie ihre E-Mail-Adresse, um Ihr Konto zu aktivieren.',
    'literal.CustomLiterals.UserRegistration.Dialog.Title': 'Registration erfolgreich',
    'literal.CustomLiterals.UserRegistration.SendButton.Text': 'Senden',
    'literal.CustomLiterals.UserRegistration.Title': 'Registrierung',
    'literal.CustomLiterals.UserSettings.Dialog.IntroText': 'Bitte ergänzen Sie Ihre Benutzerangaben, um die Applikation nutzen zu können.',
    'literal.CustomLiterals.UserSettings.Dialog.Title': 'Benutzerangaben',
    'literal.CustomLiterals.Validations.DateNotInFuture': 'Datum muss in der Zukunft liegen',
    'literal.CustomLiterals.Validations.FileInProgress' : 'Es kann erst gespeichert werden, wenn alle Uploads abgeschlossen sind',
    'literal.CustomLiterals.Validations.InvalidBirthdate' : 'Das Alter muss zwischen {{minAge}} und {{maxAge}} liegen.',
    'literal.CustomLiterals.Validations.InvalidFileUploadMaxFileSize' : 'Die maximale Dateigrösse von {{maxSizeMB}} MB darf nicht überschritten werden',
    'literal.CustomLiterals.Validations.InvalidFileUploadMaxNumber' : 'Maximal {{maxFiles}} Datei(en) erlaubt',
    'literal.CustomLiterals.Validations.InvalidFileUploadMinNumber' : 'Mindestens {{minFiles}} Datei(en) erforderlich',
    'literal.CustomLiterals.Validations.InvalidFileType': 'Es können nur Dateien des Typs {{acceptedFiles}} hochgeladen werden',
    'literal.CustomLiterals.Validations.InvalidIban': 'Ungültige IBAN',
    'literal.CustomLiterals.Validations.InvalidSocialSecurityNumber': 'Ungültige AHV-Nummer',
    'literal.CustomLiterals.VersionInfo.BackEndVersion': 'Back-End: {{version}}',
    'literal.CustomLiterals.VersionInfo.FrontEndVersion': 'Front-End: {{version}}',
    'literal.CustomLiterals.ZipPlaceLookup.NoDataText': 'PLZ oder Ort nicht gefunden',
    'literal.CustomLiterals.AuthError.Title': 'Authentication error',
    'literal.CustomLiterals.AuthError.Text': '{{error}}\n\nIf the problem persists please contact an administrator.',
    'literal.CustomLiterals.AuthErrorWithUserEmail.Text': '{{userEmail}}\n\n{{error}}\n\nIf the problem persists please contact an administrator.'
};

export type TTranslationKey =
    // SPECIAL: BUILT DYNAMICALLY AT RUNTIME: 'literal.CustomLiterals.CustomAction.<actionName>'
    TQuinoUiTranslationKey | keyof typeof defaultSharedTranslations;

/**
 * proxy function to type check translation key
 * @param key
 */
export const tKey = (key: TTranslationKey): TTranslationKey => key;
