import React, { useContext, useEffect, useRef, useState } from 'react';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Popup } from 'devextreme-react/popup';
import { CrmContextList } from './CrmContextList';
import { CrmContext } from '../../contexts/crmContext/CrmContext';
import { CrmContextSelectorCancelButton } from './CrmContextSelectorCancelButton';
import { CrmContextSearch } from './CrmContextSearch';
import { ICrmContext } from '../../crmContext';
import { ScrollablePopup } from '../Util/Devextreme/ScrollablePopup';
import { useI18n } from '../../lang/useI18n';
import { PopupButtonBar } from '../Util/Devextreme/PopupButtonBar';
import { CrmContextSearchService } from './CrmContextSearchService';
import { Markdown } from '../Util/Markdown/Markdown';
import { useTranslation } from '../../lang/useTranslation';
import { ProfileContext } from '../../contexts/index';

interface IStyles {
    throbberContainer: string;
    buttonDiv: string;
    dialogContent: string;
    noContext: string;
    dialog: string;
}

const styles: IStyles = require('./CrmContextSelector.less');

export const CrmContextSelector: React.FunctionComponent = () => {
    const i18n = useI18n();
    const crmContext = useContext(CrmContext);
    const [profileData] = useContext(ProfileContext);

    const userName = `${profileData.firstName} ${profileData.lastName}`;
    const userEmail = profileData.emailAddress;

    const [open, setOpen] = useState(true);
    const initialCrmContexts: ICrmContext[] = [];
    const [currentCrmContexts, setCurrentCrmContexts] = useState<ICrmContext[]>(initialCrmContexts);
    const dialogRef = useRef<Popup>(null);

    const repaint = () => {
        dialogRef.current && dialogRef.current.instance.repaint();
    };
    useEffect(repaint, [currentCrmContexts]);

    useEffect(
        () => {
            if (!crmContext.isLoading) {
                setCurrentCrmContexts(crmContext.availableCrmContexts);
            }
        },
        [crmContext]
    );

    const hasActiveContext = crmContext.activeCrmContext !== undefined;
    const hasAvailableContexts =
        crmContext.availableCrmContexts && crmContext.availableCrmContexts.length > 0;

    const onClose = () => {
        if (crmContext.activeCrmContext) {
            crmContext.hideContextSelector();
        }
    };

    const handleCancel = () => {
        onClose();
        setOpen(false);
    };

    const handleContextSearchChange = (searchValue: string) => {
        setCurrentCrmContexts(
            CrmContextSearchService.filterContextsForSearchString(
                crmContext.availableCrmContexts,
                searchValue
            )
        );
    };

    const titleTranslationKey = hasActiveContext
        ? 'literal.CustomLiterals.MainLayout.CrmContextChangeTitle'
        : 'literal.CustomLiterals.MainLayout.CrmContextSelectionTitle';

    const crmContextNoContextText = useTranslation(
        'literal.CustomLiterals.MainLayout.CrmContextNoContextText',
        { userName: userName, userEmail: userEmail }
    );

    return (
        <div>
            <ScrollablePopup
                onHiding={onClose}
                visible={open}
                title={i18n.t(titleTranslationKey)}
                height={'auto'}
                maxHeight={'100%'}
                width={600}
                ref={dialogRef}
                showCloseButton={hasActiveContext}
                closeOnOutsideClick={hasActiveContext}
                dragEnabled={false}
            >
                {hasAvailableContexts && (
                    <CrmContextSearch handleInputChange={handleContextSearchChange} />
                )}
                {crmContext.isLoading && (
                    <div className={styles.throbberContainer}>
                        <LoadIndicator height={32} width={32} />
                    </div>
                )}

                {!crmContext.isLoading &&
                    ((hasAvailableContexts &&
                        currentCrmContexts.length > 0 && (
                            <CrmContextList crmContexts={currentCrmContexts} />
                        )) || (
                        <div className={styles.noContext}>
                            <Markdown>{crmContextNoContextText}</Markdown>
                        </div>
                    ))}
                {!crmContext.isLoading && (
                    <PopupButtonBar>
                        <CrmContextSelectorCancelButton handleCancel={handleCancel} />
                    </PopupButtonBar>
                )}
            </ScrollablePopup>
        </div>
    );
};
