import React from 'react';
import {
    IMarkdownConverter,
    IQuinoComponentProps,
    ObjectBookmark,
    QuinoUIServiceSymbols,
    useService,
} from '@quino/ui';
import {
    getAspectOrDefault,
    getMetaProperty,
    IMetaPropertyValueService,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import {
    IPerformaLabelAspect,
    PerformaLabelAspectIdentifier,
} from '../../meta/aspects/IPerformaLabelAspect';

type TStyles = {
    markdown: string;
};
const styles: TStyles = require('./PerformaLabel.less');

export const PerformaLabel: React.FunctionComponent<IQuinoComponentProps<ObjectBookmark>> = (
    props
) => {
    const { element, bookmark } = props;
    const metaProperty = getMetaProperty(element);
    const { description } = metaProperty;

    const fieldValueService = useService<IMetaPropertyValueService>(
        QuinoCoreServiceSymbols.IMetaPropertyValueService
    );
    const value = fieldValueService.getFieldValue<string>(metaProperty, bookmark.genericObject);

    const labelAspect = getAspectOrDefault<IPerformaLabelAspect>(
        metaProperty,
        PerformaLabelAspectIdentifier
    );

    const markdownConverter = useService<IMarkdownConverter>(
        QuinoUIServiceSymbols.IMarkdownConverter
    );
    const convertedMarkdown = markdownConverter.convertToHtml(value || '');

    return (
        <div
            className={`dx-field ${styles.markdown}`}
            title={description}
            style={labelAspect ? { color: labelAspect.csstextcolor } : undefined}
            dangerouslySetInnerHTML={{ __html: convertedMarkdown }}
        />
    );
};
