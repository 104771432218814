import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { configureIocContainer } from './core/IoC';
import { IAuthenticationService, QuinoCoreServiceSymbols } from '@quino/core';
import { AuthErrorPopup } from './Components/AuthError/AuthErrorPopup';
import {
    SessionProvider,
    withStoreProvider,
    withWrapper,
    ContainerContextProvider,
} from 'performa-shared';

configureIocContainer()
    .then(async (application) => {
        const authenticationService = application.get<IAuthenticationService>(
            QuinoCoreServiceSymbols.IAuthenticationService
        );
        const params = new URL(document.location.href);
        const error = params.searchParams.get('thirdParty_auth_error');
        const userEmail = params.searchParams.get('user_email');

        if (error != null) {
            params.search = '';
            window.history.replaceState({}, 'Error', params.href);
            ReactDOM.render(
                <AuthErrorPopupPageRoot error={error} userEmail={userEmail} />,
                document.getElementById('root') as HTMLElement
            );

            return;
        }

        const accessToken = params.searchParams.get('access_token');
        if (accessToken != null && accessToken != '') {
            localStorage.setItem('_authToken', accessToken);
            params.search = '';
            window.location.href = params.href;
        }

        if (!(await authenticationService.isLoggedIn())) {
            window.location.href = process.env.BACKEND_SERVER_URL + '/thirdParty/auth/';

            return;
        }

        ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
    })
    .catch((e) => console.error(e));

const AuthErrorPopupPageRoot = withStoreProvider(
    withWrapper(ContainerContextProvider)(withWrapper(SessionProvider)(AuthErrorPopup))
);
