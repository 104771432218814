import React from 'react';
import { FloatingVersionInfo } from 'performa-shared';
import { FloatingVersionInfoMode } from 'performa-shared';

const styles: TStyles = require('./SbvLoggedOutDecorator.less');

type TStyles = {
    sbvDecorator: string;
    sbvDecoratorChildren: string;
    sbvDecoratorImageWrapper: string;
    sbvDecoratorImage: string;
};

export const SbvLoggedOutDecorator = () => {
    return (
        <div className={styles.sbvDecorator}>
            <FloatingVersionInfo mode={FloatingVersionInfoMode.Dark} />
        </div>
    );
};
