import React from 'react';

const logo = require('./logo.png');

type TStyles = {
    logoRoot: string;
};
const styles: TStyles = require('./SbvLogo.less');

export const SbvLogo = () => <img src={logo} className={styles.logoRoot} alt="SBV Logo" />;
