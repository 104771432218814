import React, { useContext, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import {
    IBookmarkFactory,
    IQuinoComponentProps,
    IQuinoDataGridProps,
    IQuinoInlineDataGridProps,
    ObjectBookmark,
    QuinoInlineDataGrid,
    QuinoMetaPanel,
    QuinoMetaPanelHeader,
    QuinoUIServiceSymbols,
    useService,
} from '@quino/ui';
import {
    getAspectOrDefault,
    getMetaRelation,
    IAuthorizationService,
    IDataService,
    IGenericObject,
    NewGenericObjectPrimaryKey,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import { ILayoutCatalogManager } from '../../DataBrowser/ILayoutCatalogManager';
import { SHARED_SERVICE_IDENTIFIER } from '../../../ioc/sharedIdentifiers';
import { DataListCreateButton } from '../../DataBrowser/DataListCreateButton';
import { PerformaMetaPanelFooter } from '../../DataBrowser/PerformaMetaPanelFooter';
import {
    EnableGridDrillDownAspectIdentifier,
    IEnableGridDrillDownAspect,
} from '../../../meta/aspects/IEnableGridDrillDownAspect';
import { useHistory } from 'react-router';
import { IRouteHelper } from '../../../navigation/IRouteHelper';
import { CrmContext } from '../../../contexts/crmContext/CrmContext';
import { SiteMapContext } from '../../SiteMapContext/SiteMapContext';
import {
    GridEditorConfigurationAspectIdentifier,
    IGridEditorConfigurationAspect,
} from '../../../meta/aspects/IGridEditorConfigurationAspect';
import { GlobalErrorContext } from '../../Error/GlobalErrorContext';

type TStyles = {
    quinoInlineDataGrid: string;
    editableGridEditor: string;
    editableGridEditorCreateButton: string;
    editableDetailPopup: string;
};
const styles: TStyles = require('./GridEditor.less');

const GridEditor = (
    props: IQuinoComponentProps<ObjectBookmark> &
        Partial<IQuinoDataGridProps> &
        Partial<IQuinoInlineDataGridProps>
) => {
    const { element } = props;

    const gridEditorConfigurationAspect = getAspectOrDefault<IGridEditorConfigurationAspect>(
        element,
        GridEditorConfigurationAspectIdentifier
    );

    return (
        <div className={styles.quinoInlineDataGrid}>
            <QuinoInlineDataGrid
                {...props}
                visible={true}
                itemsPerPage={
                    (gridEditorConfigurationAspect &&
                        gridEditorConfigurationAspect.recordsperpage) ||
                    5
                }
            />
        </div>
    );
};

export const EditableGridEditor = (props: IQuinoComponentProps<ObjectBookmark>) => {
    const { element } = props;
    const metaProperty = getMetaRelation(element);
    const { caption, targetClass } = metaProperty;
    const [, setGlobalError] = useContext(GlobalErrorContext);
    const bookmarkFactory = useService<IBookmarkFactory>(QuinoUIServiceSymbols.IBookmarkFactory);
    const layoutCatalogManager = useService<ILayoutCatalogManager>(
        SHARED_SERVICE_IDENTIFIER.ILAYOUTCATALOGMANAGER
    );
    const dataService = useService<IDataService>(QuinoCoreServiceSymbols.IDataService);

    const authorization = useService<IAuthorizationService>(
        QuinoCoreServiceSymbols.IAuthorizationService
    ).getAuthorization(metaProperty.targetClass);

    const [state, dispatch] = useState<{
        customDrillDownEnabled: boolean;
        currentObjectBookmark: ObjectBookmark;
        addNewObject: boolean;
    }>({
        customDrillDownEnabled: false,
        currentObjectBookmark: {} as ObjectBookmark,
        addNewObject: false,
    });

    const gridEditorConfigurationAspect = getAspectOrDefault<IGridEditorConfigurationAspect>(
        element,
        GridEditorConfigurationAspectIdentifier
    );

    const customDrillDown = async (target: IGenericObject) => {
        const { formLayouts, listLayouts } = await layoutCatalogManager.getLayoutsAsync(
            target.metaClass
        );

        /*
         * It seems performa is using the list layout also for details in the GridEditor.
         * Therefor list layout is used if there is no custom form layout.
         */
        const layoutsToUse = formLayouts && formLayouts.length > 1 ? formLayouts : listLayouts;
        const layout =
            layoutsToUse && (await layoutCatalogManager.getLayoutForObject(target, layoutsToUse));

        const newBookmark = layout
            ? bookmarkFactory.createObject(target, layout)
            : bookmarkFactory.createObject(target);

        if (newBookmark instanceof ObjectBookmark) {
            dispatch({
                ...state,
                customDrillDownEnabled: true,
                currentObjectBookmark: newBookmark,
                addNewObject: newBookmark.genericObject.primaryKey === NewGenericObjectPrimaryKey,
            });
        }
    };

    const onNewRecord = async () => {
        try {
            const genericObject = await dataService.getObjectAsync<IGenericObject>(
                targetClass,
                null,
                null
            );
            await customDrillDown(genericObject);
        } catch (e) {
            setGlobalError({ error: e, title: 'Grid Editor Error', logout: false });
        }
    };

    const { customDrillDownEnabled, currentObjectBookmark } = state;
    const foreignKeyKey = props.bookmark.genericObject.metaClass;
    const foreignKeyValue = props.bookmark.genericObject.primaryKey!.split('=')[1];

    const reloadParentAfterSave = async () => {
        if (
            gridEditorConfigurationAspect &&
            gridEditorConfigurationAspect.parentpropstoreloadafterchange &&
            props.bookmark.genericObject.primaryKey &&
            props.bookmark.genericObject.primaryKey !== 'null'
        ) {
            const existingPropsToReload = gridEditorConfigurationAspect.parentpropstoreloadafterchange
                .split(';')
                .filter((propToReload) =>
                    Object.keys(props.bookmark.genericObject).includes(propToReload)
                );
            if (existingPropsToReload.length > 0) {
                const reloadedGenericObject = await dataService.getObjectAsync<IGenericObject>(
                    props.bookmark.genericObject.metaClass,
                    props.bookmark.genericObject.primaryKey,
                    props.bookmark.layout.name
                );

                existingPropsToReload.forEach((propToReload) => {
                    props.bookmark.originalObject[propToReload] =
                        reloadedGenericObject[propToReload];
                    props.bookmark.genericObject[propToReload] =
                        reloadedGenericObject[propToReload];
                });
                props.bookmark.reload();
            }
        }
    };

    return (
        <div className={styles.editableGridEditor}>
            <div className="dx-theme-material-typography">
                <h5>{caption}</h5>
            </div>
            {authorization.canCreate() && (
                <div className={styles.editableGridEditorCreateButton}>
                    <DataListCreateButton onNewRecord={onNewRecord} />
                </div>
            )}
            <GridEditor
                {...props}
                drillDownMode={'All'}
                selectionMode={'Multiple'}
                showDelete={authorization.canDelete()}
                customDrillDown={customDrillDown}
            />

            {customDrillDownEnabled && (
                <Popup
                    className={styles.editableDetailPopup}
                    visible={customDrillDownEnabled}
                    maxWidth={'700px'}
                    showTitle={true}
                    showCloseButton={true}
                    closeOnOutsideClick={false} // disabled because selection of dropdown will trigger close
                    onHiding={() => {
                        state.addNewObject && props.bookmark.reload();
                        dispatch({ ...state, customDrillDownEnabled: false, addNewObject: false });
                    }}
                >
                    <ScrollView>
                        <QuinoMetaPanel bookmark={currentObjectBookmark}>
                            <QuinoMetaPanelHeader
                                position={'Header'}
                                title={currentObjectBookmark.layout.caption}
                            />
                            <PerformaMetaPanelFooter
                                position={'Footer'}
                                context={{
                                    layout: currentObjectBookmark.layout,
                                    foreignKey: {
                                        [foreignKeyKey]: foreignKeyValue,
                                    },
                                    reloadParentAfterSave: reloadParentAfterSave,
                                }}
                            />
                        </QuinoMetaPanel>
                    </ScrollView>
                </Popup>
            )}
        </div>
    );
};

export const ReadonlyGridEditor = (props: IQuinoComponentProps<ObjectBookmark>) => {
    const { element } = props;

    const routeHelper = useService<IRouteHelper>(SHARED_SERVICE_IDENTIFIER.IROUTEHELPER);

    const { siteMapContainsCurrentEntity } = useContext(SiteMapContext);
    const { activeCrmContext } = useContext(CrmContext);
    const history = useHistory();

    const enableDrillDownAspect = getAspectOrDefault<IEnableGridDrillDownAspect>(
        element,
        EnableGridDrillDownAspectIdentifier
    );
    const enableDrillDown: boolean =
        (enableDrillDownAspect && enableDrillDownAspect.enabledrilldown) || false;

    const customDrillDown = (target: IGenericObject) => {
        if (!activeCrmContext) {
            throw new Error('no CRM context selected in GridEditor');
        }

        let newEntityHistory: undefined | string[];
        if (!siteMapContainsCurrentEntity(target.metaClass)) {
            const currentEntityHistory = (routeHelper.getParamsHistoryFromUrl().length > 0 &&
                routeHelper.getParamsHistoryFromUrl()) || [
                routeHelper.getParamsSiteMapPathFromUrl(),
            ];
            newEntityHistory = [...currentEntityHistory, routeHelper.getParamsIdFromUrl()];
        }

        const url = routeHelper.getUrl(
            activeCrmContext.context,
            target.metaClass,
            target.primaryKey,
            newEntityHistory,
            false
        );
        history.push(url);
    };

    return (
        <GridEditor
            {...props}
            drillDownMode={enableDrillDown ? 'All' : 'None'}
            selectionMode={'None'}
            showDelete={false}
            customDrillDown={enableDrillDown ? customDrillDown : undefined}
        />
    );
};
