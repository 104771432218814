import {
    IApplication,
    IAuthenticationFeedback,
    IAuthenticationService,
    QuinoCoreServiceSymbols,
} from '@quino/core';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { inject, injectable } from 'inversify';
import { ContainerContext } from '@quino/ui';

interface IPerformaLoginComponentProps {
    authenticationService: IAuthenticationService;
    onLoggedIn: () => void;
}

const PerformaLoginComponent: React.FunctionComponent<IPerformaLoginComponentProps> = () => {
    document.location.reload();

    return <></>;
};

@injectable()
export class PerformaAuthenticationFeedback implements IAuthenticationFeedback {
    constructor(
        @inject(QuinoCoreServiceSymbols.IAuthenticationService)
        private authenticationService: IAuthenticationService,
        @inject(Symbol.for('IApplication')) private application: IApplication
    ) {
        this.element = document.createElement('div');
        this.element.style.width = '0px';
        this.element.style.height = '0px';
        this.element.style.position = 'absolute';
        document.body.appendChild(this.element);
    }

    requestLogin = (onResolve: () => void): void => {
        if (!this.isMounted) {
            const loginComponent = (
                <ContainerContext.Provider value={{ container: this.application }}>
                    <PerformaLoginComponent
                        authenticationService={this.authenticationService}
                        onLoggedIn={() => {
                            this.resolves.forEach((x) => x());
                            this.resolves.splice(0, this.resolves.length);
                            ReactDOM.unmountComponentAtNode(this.element);
                            this.isMounted = false;
                        }}
                    />
                </ContainerContext.Provider>
            );
            ReactDOM.render(loginComponent, this.element);
            this.isMounted = true;
        }

        this.resolves.push(onResolve);
    };

    private readonly element: HTMLElement;
    private readonly resolves: (() => void)[] = [];
    private isMounted: boolean;
}
